import type { ParsedUrlQuery } from 'node:querystring'
import type {
  DiscountDTO,
  FilterDTO,
  FilterOptionDTO,
  StoreDTO,
  StorefrontDiscountCodeListResponse
} from 'ecosystem'
import { FilterType } from 'ecosystem'
import {
  createEndpointError,
  fetchPropsFactory,
  storefrontApiCall,
  urlWithQueryParamsFactory
} from '../../utils/fetching'
import type { GetInitialProductsType } from '../../utils/filters'
import { filterValuesFactory, getFiltersByType, getFilterType } from '../../utils/filters'
import { storeEndpoint } from './constants'
import { getFilterOptions } from './getFilterOptions'

interface GetStoreQueryParams {
  query: string[]
  onlyVisibleBrands?: boolean
}

export async function getStore(params?: GetStoreQueryParams): Promise<StoreDTO> {
  const props = fetchPropsFactory()
  const url = urlWithQueryParamsFactory<GetStoreQueryParams>(storeEndpoint, { queryParams: params })
  const { data: store, error } = await storefrontApiCall<StoreDTO>(url, props)

  if (!store) {
    return createEndpointError('store', error)
  }

  store.services = store.services || []
  return store
}

export async function getFilters() {
  const props = fetchPropsFactory()
  const { data: filters, error } = await storefrontApiCall<FilterOptionDTO[]>(
    `${storeEndpoint}/filters`,
    props
  )

  if (error) {
    return createEndpointError(`${storeEndpoint}/filters`, error)
  }

  return filters
}

export interface GetDiscountCodeParams {
  code: string
}

export async function getDiscountCode(params: GetDiscountCodeParams) {
  const url = urlWithQueryParamsFactory<GetDiscountCodeParams>(`${storeEndpoint}/discount-code`, {
    queryParams: params
  })
  const props = fetchPropsFactory({
    props: {
      method: 'GET',
      credentials: 'include'
    }
  })

  return storefrontApiCall<DiscountDTO>(url, props)
}

export async function getDiscountCodeList() {
  const url = urlWithQueryParamsFactory(`${storeEndpoint}/discount-codes`)
  const props = fetchPropsFactory({
    props: {
      method: 'GET',
      credentials: 'include'
    }
  })

  return storefrontApiCall<StorefrontDiscountCodeListResponse>(url, props)
}

export interface FilterFactoryProps {
  type: GetInitialProductsType
  id: string
  priceFilter: '' | { minPrice: number; maxPrice: number } | undefined
}

export const validQueryParamFiltersFactory = async (
  queryParams: ParsedUrlQuery,
  props: FilterFactoryProps
) => {
  const initialFilter = getFiltersByType(props.id, props.type, { ...props.priceFilter })
  const filterOptionsResponse = await getFilterOptions(initialFilter)
  const validQueryParamFilters: Partial<FilterDTO>[] = []

  for (const filterKey in queryParams) {
    const matchOption = filterOptionsResponse?.options?.find(
      (f) => f.name.toLowerCase() === filterKey.toLowerCase()
    )
    if (matchOption) {
      const filterType = getFilterType(matchOption)
      const filter: Partial<FilterDTO> = {
        type: filterType,
        name: matchOption.name,
        label: matchOption.label,
        visible: Boolean(matchOption.variant),
        textValues: [],
        numberValues: []
      }
      const values = filterValuesFactory(queryParams, filterKey)

      switch (filterType) {
        case FilterType.TEXT:
          filter.textValues = values
          break
        case FilterType.NUMBER:
          filter.numberValues = values.map((e) => parseInt(e))
          break
        default:
          break
      }

      validQueryParamFilters.push(filter)
    }
  }

  return validQueryParamFilters
}
